import * as React from 'react';
import {
    Grid,
    TextField,
    Button,
    Stack,
    Box,
    Typography,
    InputAdornment,
    Stepper,
    Step,
    StepLabel,
    FormControlLabel, Checkbox, Radio, FormControl, FormLabel, RadioGroup, Select, MenuItem
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import {useEffect, useState} from 'react';
import ProfileVendorEdit from './ProfileVendorEdit';
import {VENDOR_ENDPOINT} from "../interfaces/apiConstants";
import ProfessionalsList from "./VendorsSearchResult";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import SearchIcon from '@mui/icons-material/Search';
import {Vendor2} from "../interfaces/Vendor2";
import UnsavedChangesConfirmationModal from "./common/UnsavedChangesConfirmationModal";
import Ribbon from "./Ribbon";

type ResponsiveDatePickersProps = {
    label: string;
    goBack: () => void;
    onDataChange: (data: Vendor2 | null, serviceProviderData: Vendor2 | null) => void;
    currentValue: Vendor2 | null;
    currentServiceProvider: Vendor2 | null;
};

export default function InputVendorTextField({
                                                 label,
                                                 goBack,
                                                 onDataChange,
                                                 currentValue,
                                                 currentServiceProvider,
                                             }: ResponsiveDatePickersProps) {
    const [currentVendorName, setCurrentVendorName] = useState(currentValue);
    const [searchTerm, setSearchTerm] = useState('');
    const [serviceProviderVendor, setServiceProviderVendor] = useState(currentServiceProvider);
    const [serviceProviderSearchValue, setServiceProviderSearchValue] = useState('');
    const [options, setOptions] = useState<Vendor2[]>([]);
    const [originalVendorType] = useState<String>(!serviceProviderVendor?.vendorId ? "directVendor": "brokerAgent")

    const fetchVendors = async () => {
        try {
            const response = await fetch(`${VENDOR_ENDPOINT}`);
            const data = await response.json();
            const vendorNames = data.map((vendor: any) => vendor.businessName).filter(Boolean);
            setOptions(vendorNames);
        } catch (error) {
            console.error('Failed to fetch vendors:', error);
        }
    };
    useEffect(() => {
        fetchVendors();
    }, []);

    const [vendorType, setVendorType] = useState(originalVendorType);
    const [isNewVendorClicked, setIsNewVendorClicked] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNewVendorClick = () => {
        window.open('/drawer/vendors', '_blank');
    };

    const handleCloseNewVendorClick = async () => {
        setIsNewVendorClicked(false);
        await fetchVendors(); // Call fetchVendors to refresh the vendor list
    };

    const handleSaveLocal = (vendor: Vendor2) => {
        setCurrentVendorName(vendor)
    };

    const handleSaveServiceProviderLocal = (vendor: Vendor2) => {
        setServiceProviderVendor(vendor)
    };

    const handleSave = () => {
        if(vendorType !== originalVendorType && vendorType === "directVendor"){
            const nullVendor = {
                vendorId: null,
                vendorName: ''
            }
            onDataChange(currentVendorName, nullVendor);
        } else {
            onDataChange(currentVendorName, serviceProviderVendor);
        }
        goBack();
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVendorType((event.target as HTMLInputElement).value);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleConfirmCancellation = () => {
        setIsModalOpen(false);
        goBack()
    };

    const handleCancelCancellation = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () =>{
        if (currentValue !== currentVendorName) {
            setIsModalOpen(true); // Show confirmation if there are unsaved changes
        } else {
            goBack()
        }
    }

    const steps = ['Supplier Type', 'Direct Supplier', 'Service Provider'];

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1, pb: 1, pl: 1 }}>
                <Typography variant="h5" style={{ paddingLeft: '5px' }}>Supplier</Typography>
            </Box>
            <Ribbon
                leftChildren={
                    <>

                    <Button disabled={activeStep === 0} onClick={handleBack}>
                        Back
                    </Button>
                    </>

                }
                rightChildren={
                    <>
                        {activeStep === 1 && (
                            <Button onClick={handleNewVendorClick} variant="contained" startIcon={<AddTwoToneIcon />} >
                                Add Supplier
                            </Button>
                        )}
                        {activeStep < steps.length - 1 ? (
                            <Button variant="contained" onClick={handleNext}>
                                Next
                            </Button>
                        ) : (
                            <Button variant="contained" onClick={handleSave}>
                                Save
                            </Button>
                        )}
                        <Button onClick={handleCancel}>Cancel</Button>
                    </>
                }
            />

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '80vh',
                mx: 4,
                position: 'relative'
            }}
        >
            <Stepper activeStep={activeStep} sx={{mt: 4}} >
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box sx={{ overflowY: 'auto', flexGrow: 1}}>
                {activeStep === 0 && (
                    <Box sx={{  mt: 25 }}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Specify Supplier Type</FormLabel>
                            <RadioGroup
                                aria-label="vendor-type"
                                name="vendorType"
                                value={vendorType}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="directVendor" control={<Radio />} label="Direct Supplier: Select this option if the vendor provides services directly." />
                                <FormControlLabel value="brokerAgent" control={<Radio />} label="Broker/Agent: Select this option if the vendor will arrange services through another provider. You will need to specify the actual service provider in the next step." />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                )}
                {activeStep === 1 && (
                    <Box sx={{ mt: 3 }}>
                        <Autocomplete
                            id="outlined-basic"
                            options={options}
                            value={currentValue?.vendorName}
                            onInputChange={(event, newInputValue) => {
                                setSearchTerm(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search for Suppliers"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                                    }}
                                />
                            )}
                            isOptionEqualToValue={(option, value) => option === value}
                            freeSolo
                        />
                        <Box sx={{ mt: 2 }}>
                            <ProfessionalsList searchTerm={searchTerm ? searchTerm : ""} handleSelect={handleSaveLocal} short={false}></ProfessionalsList>
                        </Box>

                    </Box>
                )}
                {activeStep === 2 && vendorType === "brokerAgent" && (
                    <Box sx={{ mt: 6 }}>
                        <Autocomplete
                            id="outlined-basic"
                            options={options}
                            value={serviceProviderVendor?.vendorName}
                            onInputChange={(event, newInputValue) => {
                                setServiceProviderSearchValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search for Suppliers"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                                    }}
                                />
                            )}
                            isOptionEqualToValue={(option, value) => option === value}
                            freeSolo
                        />
                        <Box sx={{ mt: 2 }}>
                            <ProfessionalsList searchTerm={serviceProviderSearchValue ? serviceProviderSearchValue : ""} handleSelect={handleSaveServiceProviderLocal} short={false}></ProfessionalsList>
                        </Box>
                        <Button onClick={handleNewVendorClick} variant="contained" startIcon={<AddTwoToneIcon />} sx={{ mt: 2 }}>
                            Add Vendor
                        </Button>
                    </Box>
                )}
            </Box>
            <UnsavedChangesConfirmationModal
                open={isModalOpen}
                onConfirm={handleConfirmCancellation}
                onCancel={handleCancelCancellation}
                message={"You have unsaved changes. Are you sure you want to cancel?"}
                confirmMessage={"YES, CANCEL"}
            />
        </Box>
        </Box>

    );
}
