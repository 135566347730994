import React, { useState } from 'react';
import { Box, Select, MenuItem, TextField, Typography } from '@mui/material';

interface NotificationSettingsProps {
    dailyNotificationXDaysBefore: number;
    weeklyNotificationXDaysBefore: number;
    monthlyNotificationXDaysBefore: number;
    onChange: (settings: { dailyNotificationXDaysBefore: number; weeklyNotificationXDaysBefore: number; monthlyNotificationXDaysBefore: number }) => void;
}

const NotificationSettings: React.FC<NotificationSettingsProps> = ({
                                                                       dailyNotificationXDaysBefore,
                                                                       weeklyNotificationXDaysBefore,
                                                                       monthlyNotificationXDaysBefore,
                                                                       onChange,
                                                                   }) => {
    const [type, setType] = useState('daily');
    const [value, setValue] = useState(dailyNotificationXDaysBefore);

    const handleTypeChange = (event: any) => {
        const newType = event.target.value as string;
        setType(newType);

        let newValue;
        if (newType === 'daily') {
            newValue = dailyNotificationXDaysBefore;
        } else if (newType === 'weekly') {
            newValue = weeklyNotificationXDaysBefore;
        } else {
            newValue = monthlyNotificationXDaysBefore;
        }
        setValue(newValue);
    };

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value, 10);
        setValue(newValue);

        if (type === 'daily') {
            onChange({ dailyNotificationXDaysBefore: newValue, weeklyNotificationXDaysBefore, monthlyNotificationXDaysBefore });
        } else if (type === 'weekly') {
            onChange({ dailyNotificationXDaysBefore, weeklyNotificationXDaysBefore: newValue, monthlyNotificationXDaysBefore });
        } else {
            onChange({ dailyNotificationXDaysBefore, weeklyNotificationXDaysBefore, monthlyNotificationXDaysBefore: newValue });
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>Reminder start</Typography>
            <TextField
                sx={{ mx: 1, width: 50 }}
                variant="standard"
                value={value}
                type="number"
                onChange={handleValueChange}
            />
            <Select value={type} onChange={handleTypeChange} variant="standard" sx={{ mx: 1 }}>
                <MenuItem value="daily">days</MenuItem>
                <MenuItem value="weekly">weeks</MenuItem>
                <MenuItem value="monthly">months</MenuItem>
            </Select>
            <Typography>before event</Typography>
        </Box>
    );
};

export default NotificationSettings;
