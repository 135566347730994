import {createTheme} from "@mui/material/styles";

const azureTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#0077d2', // A pink-red shade similar to the buttons and highlights
        },
        secondary: {
            main: '#000000', // A teal shade, as seen in some UI elements
        },
        background: {
            default: '#d5d2cd', // A light background
            paper: '#ffffff', // Slightly off-white for paper elements
        },
        text: {
            primary: '#2c2929', // Dark gray for primary text
            secondary: '#6d6d6d', // Lighter gray for secondary text
        },
        info: {
            main: '#2196f3', // Blue for informational elements
        },
        error: {
            main: '#f44336', // Red for error states
        },
        warning: {
            main: '#ffc107', // Amber for warning messages
        },
        success: {
            main: '#4caf50', // Green for success states
        },
    },
    typography: {
        fontFamily: '"Segoe UI", "Helvetica", "Arial", sans-serif', // The default font family
        h1: {
            fontFamily: '"Segoe UI", "Times New Roman", serif', // A slab serif for headings
            fontWeight: 800,
        },
        h2: {
            fontFamily: '"Segoe UI", "Roboto Slab", "Times New Roman", serif',
            fontWeight: 800,
        },
        h3: {
            fontFamily: '"Segoe UI", "Times New Roman", serif',
            fontWeight: 800,
        },
        h4: {
            fontFamily: '"Segoe UI", "Times New Roman", serif',
            fontWeight: 500,
            color:  '#3b3b3b' // A light background
        },
        h5: {
            fontFamily: '"Segoe UI", "Times New Roman", serif',
            fontWeight: 500,
            color:  '#3b3b3b' // A light background
        },


        // ... and so on for h4, h5, h6, subtitle1, subtitle2, button, overline
        button: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
        },
        overline: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            textTransform: 'uppercase',
        },
    },

    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    background:'linear-gradient(45deg, ##FE6B8B 30%, #FE6B8B 90%)'
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    //boxShadow: '0px 0px 30px rgba(75,56,162,0.35)',

                    //background: 'linear-gradient(0deg, #dadada 30%, #FF8E53 90%)'
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
                    body {
                        backgroundColor:  '#FFFFFF00'
                    }
                `,
        },
    },
});
const color = 'rgba(255,255,255,0)' // Green for success states
export default azureTheme;