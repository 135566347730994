// SignInForm.tsx
import React, { useState } from 'react';
import { Grid, TextField, Button, Typography, Alert, useTheme, Box } from '@mui/material';
import { useHistory } from "react-router-dom";
import { useUser } from "./UserProvider";

interface SignInFormProps {
    darkMode: boolean;
}

const SignInForm: React.FC<SignInFormProps> = ({ darkMode }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { dispatch } = useUser(); // Use the dispatch function from the context
    const history = useHistory();
    const theme = useTheme();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError('');

        // Check credentials
        if ((username === 'lonestar' && password === 'travis123') || (username === 'dev' && password === '')) {
            console.log('Login Successful');
            const id = username === 'lonestar' ? 1 : 2;
            dispatch({ type: 'LOGIN', payload: { name: username, password, id } }); // Dispatch login action
            history.push('/drawer');
        } else {
            setError('Invalid username or password');
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: '400px', padding: '20px' }}>
            <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }} color={darkMode ? '#8fb1fd' : 'textPrimary'}>
                Sign In
            </Typography>
            <form onSubmit={handleSubmit}>
                {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                <TextField
                    fullWidth
                    label="Username"
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputLabelProps={{
                        style: {
                            color: darkMode ? '#8fb1fd' : 'rgba(0, 0, 0, 0.54)',
                        },
                    }}
                    InputProps={{
                        style: {
                            color: darkMode ? '#8fb1fd' : 'black',
                        },
                        sx: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: darkMode ? '#8fb1fd' : 'black',
                                },
                                '&:hover fieldset': {
                                    borderColor: darkMode ? '#8fb1fd' : 'black',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: darkMode ? '#8fb1fd' : 'black',
                                },
                            },
                        },
                    }}
                />
                <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputLabelProps={{
                        style: {
                            color: darkMode ? '#8fb1fd' : 'rgba(0, 0, 0, 0.54)',
                        },
                    }}
                    InputProps={{
                        style: {
                            color: darkMode ? '#8fb1fd' : 'black',
                        },
                        sx: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: darkMode ? 'black' : 'black',
                                },
                                '&:hover fieldset': {
                                    borderColor: darkMode ? 'black' : 'black',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: darkMode ? 'black' : 'black',
                                },
                            },
                        },
                    }}
                />
                <Button
                    type="submit"
                    variant="outlined"
                    size="large"
                    sx={{
                        mt: 2,
                        color: darkMode ? '#8fb1fd' : 'rgba(0, 0, 0, 0.54)',
                        borderColor: darkMode ? '#8fb1fd' : 'rgba(0, 0, 0, 0.54)',
                        '&:hover': {
                            backgroundColor: darkMode ? '#8fb1fd' : 'rgba(0,0,0,0.96)',
                            color: 'white',
                        },
                        width: "200px"
                    }}
                >
                    Sign In
                </Button>
            </form>
        </Box>
    );
};

export default SignInForm;
