import React, {useState} from 'react';
import {
    Timeline,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
    TimelineContent
} from '@mui/lab';
import {Typography, Paper, Box, Divider, Tooltip, useMediaQuery, useTheme, Stack} from '@mui/material';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import DateDisplay from './DateDisplay';
import {TimelineEvent} from "../interfaces/TimeLineEvent";

interface TimelineComponentProps {
    timeLineEvents:  TimelineEvent[];
}

interface UITimelineEvent extends TimelineEvent {
    yearGroup?: string;
}

const TimelineComponent: React.FC<TimelineComponentProps> = ({ timeLineEvents }) => {

    const [ previousYear, setPreviousYear ] = useState<string>("");

    function getTimeLineEvents(events: TimelineEvent[]): UITimelineEvent[] {
        const result: UITimelineEvent[] = [...events];
        const sorted = result.sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime());

        let previousYear: number | null = null;

        sorted.forEach((event, index) => {
            const currentYear = new Date(event.eventDate).getFullYear();

            if (previousYear === null || currentYear === previousYear) {
                event.yearGroup = "";
            } else {
                event.yearGroup = previousYear.toString();
            }

            // Add corner case for the last record
            if (index === sorted.length - 1 && event.yearGroup === "") {
                event.yearGroup = previousYear ? previousYear.toString() : currentYear.toString();
            }

            previousYear = currentYear;
        });

        return sorted;
    }

    const getDiffYear = (year: string) => {
        if( year !== previousYear ){
            return year
        }else{
            return ""
        }
    }
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <Box position="relative">
            <Timeline position="left" sx={{my:4}}>
                {getTimeLineEvents(timeLineEvents)
                    .filter((event: UITimelineEvent) => !event.markedForDelete)
                    .map((event: UITimelineEvent, index: number) => {
                        const isPastEvent = new Date(event.eventDate) < new Date();
                        const color = isPastEvent ? 'secondary' : 'primary';

                        const eventDate = new Date(event.eventDate);
                        eventDate.setDate(eventDate.getDate() + 1); //HACK
                        const dateString = (eventDate.getFullYear() === 1969) ? '' : eventDate.toLocaleDateString('en-US', {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        });

                        const title = (
                            <React.Fragment>
                                {event.dailyNotificationXDaysBefore > 0 && <div>Daily: Starts {event.dailyNotificationXDaysBefore} days before event date</div>}
                                {event.weeklyNotificationXDaysBefore > 0 && <div>Weekly: Starts {event.weeklyNotificationXDaysBefore} weeks before event date</div>}
                                {event.monthlyNotificationXDaysBefore > 0 && <div>Monthly: Starts {event.monthlyNotificationXDaysBefore} months before event date</div>}
                            </React.Fragment>
                        );

                        const reminderDate = event.dailyNotificationXDaysBefore > 0 ? new Date(eventDate.getTime() - (event.dailyNotificationXDaysBefore * 24 * 60 * 60 * 1000)) : null;

                        return (
                            <TimelineItem key={event.id}>
                                {isLargeScreen && <TimelineOppositeContent sx={{mt: index !== timeLineEvents.length - 1 ? -7 : 7 }} color="text.secondary">
                                    <Typography>{event.yearGroup}</Typography>
                                </TimelineOppositeContent>}
                                <TimelineSeparator>
                                    <TimelineDot color={color} />
                                    {index !== timeLineEvents.length - 1 && <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper sx={{ minWidth: { xs: 0, sm: 300, md: 300, lg: 400 }, pr: 2, pl: 2, pt:1, pb: 1,  mb: 5, mt: -2,  ml: { xs: 0, sm: 2, md: 0, lg: 25 } }}>
                                        <Box display="flex" alignItems="center" gap="16px">
                                            <Box display="flex" alignItems="center" gap="8px">
                                                <DateDisplay date={eventDate} />
                                                <br />
                                                <Divider orientation="vertical" flexItem />
                                            </Box>

                                            <Box textAlign="left">
                                                <Typography fontWeight="bold">{event.event}</Typography>
                                                {reminderDate && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        {isPastEvent ? "Complete" : `Reminder - ${reminderDate.toLocaleDateString('en-US', {
                                                            month: 'short',
                                                            day: 'numeric',
                                                        })}`}
                                                    </Typography>
                                                )}
                                            </Box>

                                            {event.notify && false && (
                                                <Box display="flex" alignItems="center" justifyContent="center">
                                                    <Tooltip title={title}>
                                                        <NotificationsActiveTwoToneIcon />
                                                    </Tooltip>
                                                </Box>
                                            )}
                                        </Box>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })}
            </Timeline>

            <Box position="absolute" bottom={-50} left={0} display="flex" alignItems="center" gap={1}>
                <TimelineDot color="primary" />
                <Typography variant="body2">
                    Future event
                </Typography>
                <TimelineDot color="secondary" />
                <Typography variant="body2">
                    Past event
                </Typography>
            </Box>
        </Box>
    );
};

export default TimelineComponent;
