import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Container,
    CircularProgress,
    Button,
    Grid,
    Card,
    CardMedia,
    Paper,
    Divider, CardContent, Fade, Pagination
} from '@mui/material';
import { CONTRACT_ENDPOINT } from "../interfaces/apiConstants";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import OpenInNewTwoToneIcon from '@mui/icons-material/OpenInNewTwoTone';
import { Attachment } from "../interfaces/attachment";
import { documentStepRadioOptions } from "../constants/documentStepRadioOptions";
import { labelToValue, valueToLabel } from "../utils/valueToLabel";
import { useUser } from "./UserProvider";
import {Document, Page} from "react-pdf";
import MinimizeIcon from "@mui/icons-material/Minimize";
import {Maximize} from "@mui/icons-material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
interface AttachmentsPageProps {
    currentValue: Attachment[] | null;
}
const DocumentsDashboard: React.FC<AttachmentsPageProps> = ({ currentValue }) => {
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    const [thumbnails, setThumbnails] = useState<Map<string, string>>(new Map());
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedAttachment, setSelectedAttachment] = useState<Attachment | null>(null);
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);  // State to manage the PDF display
    const [loading, setLoading] = useState<boolean>(false);  // State to manage loading
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [isMaximized, setIsMaximized] = useState<boolean>(false);
    // Update attachments state when currentValue changes
    useEffect(() => {
        if (currentValue) {
            const updatedAttachments = currentValue.map(attachment => ({
                ...attachment,
            }));
            setAttachments(updatedAttachments);
        }
    }, [currentValue]);

    useEffect(() => {
        const fetchThumbnails = async () => {
            const newThumbnails = new Map<string, string>();

            for (const attachment of attachments) {
                if (attachment?.thumbnail) {
                    newThumbnails.set(attachment.id, attachment.thumbnail);
                } else {
                    try {
                        const response = await fetch(`${CONTRACT_ENDPOINT}/thumbnail/${attachment.id}`, {
                            method: 'GET',
                        });

                        if (response.ok) {
                            const blob = await response.blob();
                            const blobUrl = URL.createObjectURL(blob);
                            newThumbnails.set(attachment.id, blobUrl);
                        }
                    } catch (error) {
                        console.error('Error fetching thumbnail:', error);
                    }
                }
            }

            setThumbnails(newThumbnails);
        };

        fetchThumbnails();
    }, [attachments]);

    const handleOpenFile = async (attachmentId: string) => {
        const attachment = attachments.find(attachment => attachment.id === attachmentId);

        if (!attachment) {
            return;
        }

        setLoading(true);  // Start loading

        try {
            const fileUrl = `${CONTRACT_ENDPOINT}/file/${attachment.id}`;
            const response = await fetch(fileUrl, {
                method: 'GET',
            });

            if (!response.ok) throw new Error('Network response was not ok.');

            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            setPdfUrl(blobUrl);  // Set the PDF URL to display the PDF
            setSelectedAttachment(attachment);  // Store the selected attachment for displaying details
        } catch (error) {
            console.error('There was an error downloading the file:', error);
        } finally {
            setLoading(false);  // Stop loading
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, attachment: Attachment) => {
        setAnchorEl(event.currentTarget);
        setSelectedAttachment(attachment);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedAttachment(null);
    };

    const handleAIOpen = () => {
        // Logic to open AI tools
        handleMenuClose();
    };

    const handleFileOpen = () => {
        if (selectedAttachment) {
            handleOpenFile(selectedAttachment.id);
        }
        handleMenuClose();
    };

    const handleBackClick = () => {
        setPdfUrl(null);  // Close the PDF and show the cards again
        setSelectedAttachment(null);
    };
    interface LoadSuccess {
        numPages: number;
    }
    const onDocumentLoadSuccess = ({ numPages }: LoadSuccess) => {
        setNumPages(numPages);
        setPageNumber(1); // Reset to first page when a new document is loaded
    };

    // Group attachments by state
    const groupedAttachments = attachments.reduce((acc, attachment) => {
        if (!acc[attachment.state]) {
            acc[attachment.state] = [];
        }
        acc[attachment.state].push(attachment);
        return acc;
    }, {} as { [key: string]: Attachment[] });

    const { state: { user } } = useUser(); // Destructure to get user from state

    const toggleMaximize = () => {
        setIsMaximized(!isMaximized);
    };

    return (
        <>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : pdfUrl && selectedAttachment ? (
                <Fade in={!loading} timeout={400}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isMaximized ? 'column' : 'row',
                            gap: 2,
                        }}
                    >
                        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                                sx={{
                                    border: '1px solid #ddd',
                                    p: 2,
                                    pt: 5,
                                    width: isMaximized ? 800 : 500,
                                    position: 'relative',
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    sx={{ position: 'absolute', top: 0, right: 0 }}
                                    onClick={toggleMaximize}
                                >
                                    {isMaximized ? <OpenInFullIcon /> : <OpenInFullIcon />}
                                </IconButton>

                                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} loading={null} onLoadError={() => {}}>
                                    <Page
                                        width={isMaximized ? 800 : 500}
                                        pageNumber={pageNumber}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={false}
                                    />
                                </Document>

                                {numPages && (
                                    <Pagination
                                        count={numPages}
                                        page={pageNumber}
                                        onChange={(event, page) => setPageNumber(page)}
                                        sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                                    />
                                )}
                            </Box>
                        </Container>

                        {!isMaximized && (
                            <Box
                                sx={{
                                    p: 2,
                                    width: 400,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start', // Align items to the start (top) horizontally
                                    justifyContent: 'flex-start', // Align items to the start (top) vertically
                                }}
                            >
                                <Typography variant="h6" align="center">Document Details</Typography>
                                <Typography variant="body1" align="center">
                                    <strong>Name:</strong> {selectedAttachment?.name}
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={{ mt: 2 }}
                                    onClick={handleBackClick}
                                >
                                    Back to Documents
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Fade>
            ) : (
                <Box sx={{ px: 3, mt: 1, mb: 3 }}>
                    {Object.keys(groupedAttachments).map((state, index) => (
                        <React.Fragment key={state}>
                            <Container maxWidth="lg" sx={{ px: 2 }}>
                                <Typography variant="h6" gutterBottom sx={{ mt: index > 0 ? 4 : 0 }}>
                                    {valueToLabel(state, documentStepRadioOptions)}
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                                    {groupedAttachments[state].map((attachment) => (
                                        <Box key={attachment.id} sx={{ position: 'relative', width: 200 }}>
                                            <Card
                                                sx={{
                                                    cursor: 'pointer',
                                                    height: 300,
                                                    width: '100%',
                                                    border: '2px solid transparent',
                                                    '&:hover': {
                                                        borderColor: 'primary.main',
                                                    },
                                                }}
                                                onClick={() => handleOpenFile(attachment.id)}
                                            >
                                                {thumbnails.get(attachment.id) ? (
                                                    <CardMedia
                                                        component="img"
                                                        image={thumbnails.get(attachment.id)}
                                                        alt={attachment.name}
                                                        sx={{ height: 220, width: '100%', mt: 1 }}
                                                    />
                                                ) : (
                                                    <Paper sx={{ height: 220, backgroundColor: '#f0f0f0' }} elevation={0} />
                                                )}
                                                <Divider />
                                                <CardContent sx={{ ml: -1 }}>
                                                    <Typography variant="body1" noWrap>{attachment.name}</Typography>
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => handleMenuOpen(e, attachment)}
                                                        sx={{ position: 'absolute', bottom: 30, right: -10 }}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    ))}
                                </Box>
                            </Container>
                        </React.Fragment>
                    ))}
                </Box>
            )}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleFileOpen}>
                    <OpenInNewTwoToneIcon />
                    <span style={{ paddingLeft: '8px' }}>Open</span>
                </MenuItem>
                <MenuItem onClick={handleAIOpen}>
                    <AutoAwesomeTwoToneIcon />
                    <span style={{ paddingLeft: '8px' }}>Run AI Tools</span>
                </MenuItem>
            </Menu>
        </>
    );
};

export default DocumentsDashboard;
