import React, { useState, useEffect } from 'react';
import { Box, TextField, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import generateNewId from '../utils/generateId';
import { TimelineEvent } from '../interfaces/TimeLineEvent';
import NotificationSettings from './NotificationSettings';
import dayjs from "dayjs";

interface TimelineProps {
    goBack: () => void;
    onDataChange: (contractTimeLineEvents: TimelineEvent[]) => void;
    currentValue: TimelineEvent[] | null;
    editable: boolean;
    title: string;
    addEventTrigger?: number; // Make it optional
}

const darkenColor = (color: string, factor: number) => {
    let colorInt = parseInt(color.slice(1), 16);
    let r = (colorInt >> 16) & 255;
    let g = (colorInt >> 8) & 255;
    let b = colorInt & 255;
    r = Math.max(0, r - factor);
    g = Math.max(0, g - factor);
    b = Math.max(0, b - factor);
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

const TimelineList: React.FC<TimelineProps> = ({
                                                   currentValue,
                                                   goBack,
                                                   onDataChange,
                                                   editable,
                                                   title,
                                                   addEventTrigger
                                               }: TimelineProps) => {
    const [rows, setRows] = useState<TimelineEvent[]>(currentValue ? currentValue : []);

    useEffect(() => {
        onDataChange(rows);
    }, [rows]);

    useEffect(() => {
        handleAddEvent();
    }, [addEventTrigger]);

    const handleAddEvent = () => {
        const id = generateNewId();
        setRows((oldRows) => [
            {
                id,
                eventDate: new Date(),
                event: '',
                eventType: '',
                markedForDelete: false,
                notify: true,
                dailyNotificationXDaysBefore: 2,
                weeklyNotificationXDaysBefore: 0,
                monthlyNotificationXDaysBefore: 0,
            },
            ...oldRows,  // Prepend new event to the beginning
        ]);
    };

    const handleInputChange = <T extends keyof TimelineEvent>(index: number, field: T, value: TimelineEvent[T]): void => {
        const updatedRows: TimelineEvent[] = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    const handleDeleteEvent = (index: number) => {
        const updatedRows: TimelineEvent[] = [...rows];
        updatedRows[index].markedForDelete = true;
        setRows(updatedRows);
    };

    const baseColor = '#cbc1fa';
    const darkeningFactor = 15;

    const displayRows = rows.filter(row => !row.markedForDelete);

    return (
        <Box>
            <Box sx={{ overflow: 'auto' }}>
                <List>
                    {displayRows.map((row, index) => (
                        <Paper
                            key={row.id}
                            sx={{
                                m: 2,
                                p: 2,
                                borderLeft: 7,
                                borderRight: 1,
                                borderTop: 1,
                                borderBottom: 1,
                                borderColor: 'rgba(178,178,178,0.69)',
                                borderRadius: 2
                            }}
                            elevation={0}
                        >
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <TextField
                                            value={row.event}
                                            onChange={(e) => handleInputChange(index, 'event', e.target.value)}
                                            fullWidth
                                            variant="standard"
                                            disabled={!editable}
                                            InputProps={{ style: { fontSize: '.9rem' } }}
                                        />
                                    }
                                    secondary={
                                        <>
                                            <TextField
                                                type="date"
                                                value={dayjs(row.eventDate).format('YYYY-MM-DD')}
                                                onChange={(e) => handleInputChange(index, 'eventDate', new Date(e.target.value))}
                                                fullWidth
                                                variant="standard"
                                                disabled={!editable}
                                                InputProps={{ style: { fontSize: '0.8rem' } }}
                                            />
                                            <Box mt={1}>
                                                <NotificationSettings
                                                    dailyNotificationXDaysBefore={row.dailyNotificationXDaysBefore}
                                                    weeklyNotificationXDaysBefore={row.weeklyNotificationXDaysBefore}
                                                    monthlyNotificationXDaysBefore={row.monthlyNotificationXDaysBefore}
                                                    onChange={(settings) => {
                                                        handleInputChange(index, 'dailyNotificationXDaysBefore', settings.dailyNotificationXDaysBefore);
                                                        handleInputChange(index, 'weeklyNotificationXDaysBefore', settings.weeklyNotificationXDaysBefore);
                                                        handleInputChange(index, 'monthlyNotificationXDaysBefore', settings.monthlyNotificationXDaysBefore);
                                                    }}
                                                />
                                            </Box>
                                        </>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    {editable && (
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteEvent(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Paper>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default TimelineList;
