import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Grid,
    Typography,
    Card,
    CardMedia,
    CardContent,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Button,
    Toolbar,
    AppBar,
    Paper,
} from '@mui/material';
import { CONTRACT_ENDPOINT } from "../interfaces/apiConstants";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewTwoToneIcon from '@mui/icons-material/OpenInNewTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';
import UnsavedChangesConfirmationModal from "./common/UnsavedChangesConfirmationModal";
import ServiceAgreementModal from './ServiceAgreementModal';
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import DocumentsDashboard from "./DocumentsDashboard";
import {generateThumbnail} from "../apis/fetchAttachment";
import {Attachment} from "../interfaces/attachment";
import Ribbon from "./Ribbon"; // Import the new modal



interface AttachmentsPageProps {
    goBack: () => void;
    onDataChange: (attachments: Attachment[], contractPayload: any) => void;
    currentValue: Attachment[] | null;
    contractPayload: any;
}

const DocumentsEdit: React.FC<AttachmentsPageProps> = ({ goBack, onDataChange, currentValue, contractPayload }) => {
    const [attachments, setAttachments] = useState<Attachment[]>(currentValue ? currentValue : []);
    const [isServiceAgreementModalOpen, setIsServiceAgreementModalOpen] = useState(false); // State for the new modal
    const handleSubmitAttachment = (contractPayload: any, newAttachment: Attachment) => {

        let updatedAttachments = attachments;
        updatedAttachments = [...updatedAttachments, newAttachment]

        onDataChange(updatedAttachments, contractPayload);
        setAttachments([...attachments, newAttachment]);

    };

    const handleCancel = () => {
        goBack();
    };

    const handleReplace = () => {
        // Replace the current service agreement
        setIsServiceAgreementModalOpen(false);
    };

    const handleCreateNew = () => {
        setIsServiceAgreementModalOpen(true);
        // Archive the current version and create a new one
    };

    return (
        <>
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1, pb: 1, pl: 1 }}>
                    <Typography variant="h5" style={{ paddingLeft: '5px' }}>Documents</Typography>
                </Box>

                <Ribbon
                    leftChildren={
                        <Button onClick={handleCreateNew} variant="contained" sx={{ mr: 3 }}>
                            Add Documents
                        </Button>
                    }
                    rightChildren={
                        <Button onClick={handleCancel}>
                            Cancel
                        </Button>
                    }
                />
                <br/>
                <DocumentsDashboard currentValue={attachments}></DocumentsDashboard>
                <ServiceAgreementModal
                    attachments={attachments}
                    open={isServiceAgreementModalOpen}
                    onClose={() => setIsServiceAgreementModalOpen(false)}
                    currentContractPayload={contractPayload}
                    handleSubmit={handleSubmitAttachment}
                    newContract={false}
                />
            </Box>
        </>
    );
}

export default DocumentsEdit;
