import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {TreeItem, TreeItemProps} from "@mui/x-tree-view/TreeItem";
import {TreeView} from "@mui/x-tree-view/TreeView";
import * as React from "react";
import {Button, IconButton} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {Contract} from "../interfaces/contract";
import {useUser} from "./UserProvider";
import {fetchContracts} from "../apis/fetchContracts";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function CustomTreeView() {

    interface CustomTreeItemProps extends TreeItemProps {
        labelText: string;
    }

    const history = useHistory();

    function onIconClick(labelText: string) {
        console.log(labelText)

        if( labelText === 'Accounts'){
            history.push('/drawer/accounts');
        }else if( labelText === 'Projects'){
            history.push('/drawer/projects');
        }else if( labelText === 'Contracts'){
            history.push('/drawer/contracts');
        }

    }


    function CustomTreeItem({ nodeId, labelText, ...otherProps }: CustomTreeItemProps) {
        const label = (
            <div style={{ display: 'flex', alignItems: 'center' }} onClick={(e) => e.stopPropagation()}>
                <span>{labelText}</span>
                <IconButton size="small" onClick={(e) => {
                    onIconClick(labelText); // Pass the labelText and the type of icon
                    e.stopPropagation(); // Prevent event from bubbling up
                }}>
                    <AddBoxIcon style={{ fontSize: '1rem' }} />
                </IconButton>
            </div>
        );

        return (
            <TreeItem nodeId={nodeId} label={label} {...otherProps} />
        );
    }

    function onContractOpen(contractId: string) {
        const id = contractId.split("-").at(1)
        history.push("/drawer/contract/" + id);
    }

    function CustomContractTreeItem({ nodeId, labelText, ...otherProps }: CustomTreeItemProps) {
        const label = (
            <div style={{ display: 'flex', alignItems: 'center' }} onClick={(e) => e.stopPropagation()}>
                <span>{labelText}</span>
                <IconButton size="small" onClick={(e) => {
                    onContractOpen(nodeId); // Pass the labelText and the type of icon
                    e.stopPropagation(); // Prevent event from bubbling up
                }}>
                    <OpenInNewIcon style={{ fontSize: '1rem' }} />
                </IconButton>
            </div>
        );

        return (
            <TreeItem nodeId={nodeId} label={label} {...otherProps} />
        );
    }

    const [expanded, setExpanded] = React.useState<string[]>([]);

    const handleExpandClick = () => {
        setExpanded((oldExpanded) =>
            oldExpanded.length === 0 ? ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'] : [],
        );
    };
    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const [contracts, setContracts] = useState<Contract[]>([]);

    const { state: { user } } = useUser(); // Destructure to get user from state

    useEffect(() => {
        if (user?.id) {
            fetchContracts(user.id)
                .then((sortedContracts: React.SetStateAction<Contract[]>) => {
                    setContracts(sortedContracts);
                })
                .catch(error => {
                    console.error("Failed to fetch contracts:", error);
                });
        }
    }, [user?.id]); // Include user.id in the dependency array


    return (
        <Box sx={{ mb: 1 }}>
            <Button onClick={handleExpandClick}>
                {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
            </Button>
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ExpandLessIcon/>}
            expanded={expanded}
            onNodeToggle={handleToggle}
            sx={{ height: 240, flexGrow: 1, overflowY: 'auto' }}
        >
            <CustomTreeItem nodeId="1" labelText="Accounts" >
                {/* First Account */}
                <TreeItem nodeId="2" label="Property Manager LPA">
                    {/* Projects for Property Manager LPA */}
                    <CustomTreeItem nodeId="3" labelText="Projects">
                        {/* Example Project */}
                        <TreeItem nodeId="4" label="Project A">
                            {/* Contracts for Project A */}
                            <CustomTreeItem nodeId="3" labelText="Contracts" >
                                {/* Map over contracts and create a TreeItem for each */}
                                {contracts.map((contract, index) => (
                                    <CustomContractTreeItem
                                        key={contract.contractId}
                                        nodeId={`contract-${contract.contractId}`}
                                        labelText={contract.contractName}/>
                                ))}
                            </CustomTreeItem>
                        </TreeItem>
                        {/* Another Example Project */}
                        <TreeItem nodeId="7" label="Project B">
                            {/* Contracts for Project B */}
                            <TreeItem nodeId="8" label="Contracts">
                                {/* Example Contract */}
                                <TreeItem nodeId="9" label="Contract B1" />
                            </TreeItem>
                        </TreeItem>
                    </CustomTreeItem>
                </TreeItem>
                {/* Second Account */}
                <TreeItem nodeId="10" label="Property Manager US LLC">
                    {/* Projects for Property Manager US LLC */}
                    <TreeItem nodeId="11" label="Projects">
                        {/* Example Project */}
                        <TreeItem nodeId="12" label="Project C">
                            {/* Contracts for Project C */}
                            <TreeItem nodeId="13" label="Contracts">
                                {/* Example Contract */}
                                <TreeItem nodeId="14" label="Contract C1" />
                            </TreeItem>
                        </TreeItem>
                    </TreeItem>
                </TreeItem>
            </CustomTreeItem>
        </TreeView>
        </Box>
    );
}