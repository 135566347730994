import React from 'react';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';

interface DateDisplayProps {
    date: Date;
}

const DateDisplay: React.FC<DateDisplayProps> = ({ date }) => {
    const month = dayjs(date).format('MMM');
    const day = dayjs(date).format('DD');
    const year = dayjs(date).format('YYYY');

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="body2" sx={{  fontWeight: 'bold', color: '#757575' }}>
                 {day}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#757575', lineHeight: 1 }}>
                {month.toUpperCase()}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold',color: '#757575' }}>
                {year}
            </Typography>
        </Box>
    );
};

export default DateDisplay;
