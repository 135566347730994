import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Dashboard from "./Dashboard";
import CustomTable from "./Dashboard";
import {BrowserRouter as Router,  Link as RouterLink, Route, Switch, useHistory} from "react-router-dom";
import Selection from "./Selection";
import Auth from "../containers/Login/Auth";
import ContractPage from "./ContractPage";
import ProjectTable from "./ProjectsTable";
import FolderCopyTwoToneIcon from '@mui/icons-material/FolderCopyTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import HailTwoToneIcon from '@mui/icons-material/HailTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import RequestQuoteTwoToneIcon from '@mui/icons-material/RequestQuoteTwoTone';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import RoofingTwoToneIcon from '@mui/icons-material/RoofingTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import HubTwoToneIcon from '@mui/icons-material/HubTwoTone';
import VendorTable from "./VendorList";
import {
    Accordion,
    Breadcrumbs,
    Button, Fade,
    FormControlLabel,
    Paper,
    Switch as MaterialSwitch,
    useTheme
} from '@mui/material';
import {getCSS} from "../themes/themeUtils";
import {useEffect, useState} from "react";
import ProfileVendor from "./ProfileClient";
import ProfileContactForm from "./ProfileContactForm";
import ProfileAboutForm from "./ProfileAboutForm";
import ProfileAddLicenseForm from "./ProfileAddLicenseForm";
import ProfileServiceDetails from "./ProfileServiceDetails";
import ProfileProjectShowcase from "./ProfileProjectShowcase";
import BusinessPageForm from "./ProfileBusinessForm";
import BusinessProfile from "./ProfilePreview";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BusinessDataGrid from "./Accounts";
import ServiceSearchForm from "./VendorSearch";
import ProfessionalsList from "./VendorsSearchResult";
import ReviewsIcon from '@mui/icons-material/Reviews';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import SpaceDashboardTwoToneIcon from '@mui/icons-material/SpaceDashboardTwoTone';
import HomeDashboard from "./Home";
import BackgroundVideo from "./BackgroundVideo";
import InvoiceScreen from "./Invoices";
import VendorReview from "./VendorReview";
import VendorList from "./VendorList";
import Quotes from "./Quotes";
import EventList from "./Quotes";
import MyCalendar from "./Calendar";
import MessagingInterface from "./Quote";
import MultipleSelectCheckmarks from "./AccountDropDown";
import VendorProfile from "./VendorProfile";
import eTask from "./Alerts";
import JobPostForm from "./JobPostCreate";
import ContractEdit from "./ContractEdit";
import AddContract from "./AddContract";
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Import the icon for the log out button
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StoreMallDirectoryTwoToneIcon from '@mui/icons-material/StoreMallDirectoryTwoTone';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TreeView } from '@mui/x-tree-view/TreeView';
import {TreeItem, TreeItemProps} from '@mui/x-tree-view/TreeItem';
import AddIcon from "@mui/icons-material/Add";
import AddBoxIcon from '@mui/icons-material/AddBox'; // Import the icon you want to use
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CustomTreeView from "./CustomTreeView";
import {usePreview} from "./provider/PreviewContext";
import Chip from "@mui/material/Chip";
import {useAccount} from "./provider/AccountProvider";
import Link from "@mui/material/Link"
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import BreadcrumbLinks from "./BreadcrumbLinks";
const drawerWidth = 220;
interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window?: () => Window;
    onThemeToggle: (name: string) => void; // Function to toggle the theme
}

const label = { inputProps: { 'aria-label': 'Switch demo' } };
export default function ResponsiveDrawer(props: Props) {
    const { window, onThemeToggle } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [activeItem, setActiveItem] = React.useState('');

    const { state: { previewEnabled }, dispatch: previewDispatch } = usePreview(); // Use the preview state and dispatch
    const { state: accountState, dispatch: accountDispatch } = useAccount();
    const togglePreview = () => {
        previewDispatch({ type: 'TOGGLE_PREVIEW' }); // Dispatch action to toggle preview
    };
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [mode, setMode] = React.useState("Client");

    const handleChange  = (event: React.ChangeEvent<HTMLInputElement>) =>{
        if(event.target.checked){
            setMode("Client")
        } else {
            setMode("Vendor")
        }
    }

    const [theme, setTheme] = React.useState('day'); // Default theme

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
        onThemeToggle(theme);
    };


    const RouterLinkStyle = {
        textDecoration: 'none',
        color: useTheme().palette.text.primary, // Use the primary text color from the theme
        fontFamily: "LatinoType"
    };

    const handleHome = () => {
        setActiveItem("Home");
        history.push('/drawer/');
    };

    const handleProfile = () => {
        history.push('/drawer/profile');
    };

    const handleSearch = () => {
        history.push('/drawer/search');
    };

    const handleInvoices = () => {
        history.push('/drawer/invoices');
    };

    const handleContracts = () => {
        setActiveItem("Contracts")
        history.push('/drawer/contracts');
    };

    const handleProjects = () => {
        history.push('/drawer/projects');
    };

    const history = useHistory();

    const handleVendors = () => {
        setActiveItem("Suppliers")
        history.push('/drawer/vendors');
    };

    const handleAccounts = () => {
        setActiveItem("Accounts");
        history.push('/drawer/accounts');
    };

    const handleReviews = () =>{
        history.push('/drawer/reviews');
    }

    const handleQuotes = () =>{
        history.push('/drawer/quotes');
    }

    const handleCalendar = () => {
        history.push('/drawer/calendar');
    }

    const handleAlerts = () => {
        history.push('/drawer/eTask');
    }


    const handleLogout = () => {
        accountDispatch({ type: 'CLEAR_ACCOUNT' });
        history.push('/');
    };

    const getListItemButtonStyles = (itemName: string) => ({
        bgcolor: activeItem === itemName ? 'grey.200' : 'inherit',
        borderRadius: activeItem === itemName ? '0 16px 16px 0' : '0',
        mr: 2,
        '&:hover': {
            bgcolor: activeItem === itemName ? 'grey.400' : 'grey.100',
        },
        transition: 'background-color 0.3s, border-radius 0.3s',
    });
    const [isMinimized, setIsMinimized] = useState(false);
    const handleToggle = () => {
        setIsMinimized(prevState => !prevState);
    };


    const drawer = ( //'#FFFFFF00'
        <Box sx={{  backgroundColor: 'rgba(48,148,71,0)', height: "100vh" } }>

            {/*
            <FormControlLabel style={{paddingLeft: '10px'}}
                              control={
                                  <MaterialSwitch {...label} defaultChecked  onChange={handleChange}
                                                  color="secondary" />
                              }
                              label={mode}
            />
            <FormControlLabel style={{paddingLeft: '10px'}}
                              control={
                                  <MaterialSwitch {...label} defaultChecked  onChange={toggleTheme}
                                                  color="primary" />
                              }
                              label={theme}
            />
               */}
            <Box
                sx={{
                    /*display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pl: 1,*/
                    mt: 1,
                    ml: 8, // Reserve space for scrollbar
                    mb: 8, // Reserve space for scrollbar


                    // any other styling you need
                }}
            >
                {/* If logo is in the public folder */}
                <img src="/travis_logo3.png" alt="Logo" style={{ height: 40
                }} />

            </Box>
            <IconButton
                onClick={handleToggle}
                sx={{
                    position: 'absolute',
                    top: '5px',
                    left: '5px',
                    transition: 'left 0.3s ease',
                    color: 'primary.main'

                }}
            >
                {<ChevronLeft/>}
            </IconButton>
            <div style={{paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px'}}>
                <MultipleSelectCheckmarks></MultipleSelectCheckmarks>

            </div>
            { previewEnabled && ( <Accordion sx={{boxShadow: 'none', border: 'none', margin: 0, padding: 0,
                '&:hover': {
                    backgroundColor: '#f3f3f3', // Change this color as needed
                    cursor: 'pointer'
                }
            }}>
                <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <ArrowForwardIosIcon style={{ marginRight: '30px' }}/>  Directory
                    <Chip
                        label="Preview"
                        size="small"
                        sx={{
                            ml: 1,
                            bgcolor: 'grey.300',  // Adjusted for better contrast with white text
                            color: 'white'  // Sets the text color to white
                        }}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <Paper sx={{pt:2, pl:1}}>
                        <CustomTreeView></CustomTreeView>
                    </Paper>


                </AccordionDetails>
            </Accordion> ) }
            <br/>
            <List style={{ paddingTop: 0}}>
                <ListItem key={'Accounts'} disablePadding onClick={handleAccounts}>
                    <ListItemButton
                        sx={getListItemButtonStyles('Accounts')}
                    >
                        <ListItemIcon sx={{ color: activeItem === 'Accounts' ? 'primary.main' : 'grey.600' }}>
                            <AccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText primary="Accounts"
                                      primaryTypographyProps={{
                                          style: {
                                              fontWeight: activeItem === 'Accounts' ? 'bold' : 'normal'
                                          }
                                      }}
                        />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Home'} disablePadding onClick={handleHome}>
                    <ListItemButton
                        sx={getListItemButtonStyles('Home')}
                    >
                        <ListItemIcon sx={{ color: activeItem === 'Home' ? 'primary.main' : 'grey.600' }}>
                            <SpaceDashboardTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home"
                                      primaryTypographyProps={{
                                          style: {
                                              fontWeight: activeItem === 'Home' ? 'bold' : 'normal'
                                          }
                                      }}
                        />
                    </ListItemButton>
                </ListItem>
                { previewEnabled && (<ListItem key={'Profile'} disablePadding onClick={handleProfile}>
                    <ListItemButton>
                        <ListItemIcon>
                            <PersonOutlineTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Profile" />
                        <Chip
                            label="Preview"
                            size="small"
                            sx={{
                                ml: 1,
                                bgcolor: 'grey.300',  // Adjusted for better contrast with white text
                                color: 'white'  // Sets the text color to white
                            }}
                        />
                    </ListItemButton>
                </ListItem>)}

            </List>
            { previewEnabled && (<List>
                <div>
                    <ListItem key={'Vendor Hub'} disablePadding onClick={handleSearch}>
                        <ListItemButton>
                            <ListItemIcon>
                                <HubTwoToneIcon />
                            </ListItemIcon>
                            <RouterLink to="/projects" style={RouterLinkStyle}>
                                <ListItemText primary={(mode==='Client')? "Job Search" : "Talent Search" }/>
                            </RouterLink>
                            <Chip
                                label="Preview"
                                size="small"
                                sx={{
                                    ml: 1,
                                    bgcolor: 'grey.300',  // Adjusted for better contrast with white text
                                    color: 'white'  // Sets the text color to white
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={'Quotes'} disablePadding onClick={handleQuotes}>
                        <ListItemButton>
                            <ListItemIcon>
                                <RequestQuoteTwoToneIcon />
                            </ListItemIcon>
                            <ListItemText primary="Job Posts" />
                            <Chip
                                label="Preview"
                                size="small"
                                sx={{
                                    ml: 1,
                                    bgcolor: 'grey.300',  // Adjusted for better contrast with white text
                                    color: 'white'  // Sets the text color to white
                                }}
                            />
                        </ListItemButton>
                    </ListItem></div>
                {/*<ListItem key={'Reviews'} disablePadding onClick={handleReviews}>
                    <ListItemButton>
                        <ListItemIcon>
                            <ReviewsIcon />
                        </ListItemIcon>
                        <RouterLink to="/projects" style={RouterLinkStyle}>
                            <ListItemText primary="Reviews" />
                        </RouterLink>
                    </ListItemButton>
                </ListItem>*/}
            </List>  )}
            <Divider />

            <List>
                <ListItem key={'Contracts'} disablePadding  onClick={handleContracts}>
                    <ListItemButton
                        sx={getListItemButtonStyles('Contracts')}
                    >
                        <ListItemIcon  sx={{ color: activeItem === 'Contracts' ? 'primary.main' : 'grey.600' }}>
                            <ArticleTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Contracts"
                                      primaryTypographyProps={{
                                          style: {
                                              fontWeight: activeItem === 'Contracts' ? 'bold' : 'normal'
                                          }
                                      }}
                        />
                    </ListItemButton>
                </ListItem>
                { previewEnabled && (<ListItem key={'Projects'} disablePadding onClick={handleProjects}>
                    <ListItemButton>
                        <ListItemIcon>
                            <FolderCopyTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Projects" />
                        <Chip
                            label="Preview"
                            size="small"
                            sx={{
                                ml: 1,
                                bgcolor: 'grey.300',  // Adjusted for better contrast with white text
                                color: 'white'  // Sets the text color to white
                            }}
                        />
                    </ListItemButton>
                </ListItem> ) }
                <ListItem key={'Vendors'} disablePadding onClick={handleVendors}>
                    <ListItemButton
                        sx={getListItemButtonStyles('Suppliers')}
                    >
                        <ListItemIcon sx={{ color: activeItem === 'Suppliers' ? 'primary.main' : 'grey.600' }}>
                            <HailTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Suppliers"
                                      primaryTypographyProps={{
                                          style: {
                                              fontWeight: activeItem === 'Suppliers' ? 'bold' : 'normal'
                                          }
                                      }}
                        />
                    </ListItemButton>
                </ListItem>
                { previewEnabled && (<ListItem key={'Payments'} disablePadding onClick={handleInvoices}>
                    <ListItemButton>
                        <ListItemIcon>
                            <DescriptionTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Payments" />
                        <Chip
                            label="Preview"
                            size="small"
                            sx={{
                                ml: 1,
                                bgcolor: 'grey.600',  // Adjusted for better contrast with white text
                                color: 'white'  // Sets the text color to white
                            }}
                        />
                    </ListItemButton>
                </ListItem> ) }
            </List>
            { previewEnabled && (<List>
                <ListItem key={'Alerts'} disablePadding onClick={handleAlerts}>
                    <ListItemButton>
                        <ListItemIcon>
                            <NotificationsActiveTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Alerts" />
                        <Chip
                            label="Preview"
                            size="small"
                            sx={{
                                ml: 1,
                                bgcolor: 'grey.600',  // Adjusted for better contrast with white text
                                color: 'white'  // Sets the text color to white
                            }}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'Calendar'} disablePadding onClick={handleCalendar}>
                    <ListItemButton>
                        <ListItemIcon>
                            <AssessmentTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Calendar" />
                        {/* Adding the Chip next to the ListItemText */}
                        <Chip
                            label="Preview"
                            size="small"
                            sx={{
                                ml: 1,
                                bgcolor: 'grey.600',  // Adjusted for better contrast with white text
                                color: 'white'  // Sets the text color to white
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>)}

            <List>
                {/* ... other list items ... */}
                <ListItem key={'Logout'} disablePadding onClick={handleLogout}>
                    <ListItemButton>
                        <ListItemIcon sx={{ color: activeItem === 'Log Out' ? 'primary.main' : 'grey.600' }}>
                            <ExitToAppIcon /> {/* Log out icon */}
                        </ListItemIcon>
                        <ListItemText primary="Log Out" />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <Box sx={{ p: 2 }}>
                <FormControlLabel
                    control={
                        <MaterialSwitch
                            checked={previewEnabled}
                            onChange={togglePreview}
                            name="previewToggle"
                        />
                    }
                    label="Enable Preview"
                />
            </Box>
        </Box>
    );

    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;

    const test = "#0098f5";
    const test2 = "rgba(0,255,196,0.57)";

    return (
        <Box sx={{  background:  'linear-gradient(90deg, #FFFFFF 70%, #00FFC491 100%)', height:"100vh" } }>
        <Box sx={{ background:  'linear-gradient(90deg, #FFFFFF 5%, #8049FC8B 70%)', height:"100vh"  } }>
        <Box sx={{  display: 'flex', background:  "rgba(255,255,255,0.65)", height:"100vh"  } }>
            <Box
                sx={{
                    width: { sm: isMinimized ? 0 : drawerWidth },
                    flexShrink: { sm: 0 },
                    transition: 'width 0.3s ease',
                }}
            >
                <Fade in={!isMinimized} timeout={500}>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: isMinimized ? 0 : drawerWidth,
                            borderRight: 'none',
                            backgroundColor: 'rgba(133,52,52,0)',
                        },
                    }}
                    open={!isMinimized}
                >
                    {drawer}
                </Drawer>
                </Fade>
                {isMinimized && (<IconButton
                    onClick={handleToggle}
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        left: '5px',
                        transition: 'left 0.3s ease',
                        color: 'primary.main'

                    }}
                >
                    { <ChevronRight />}
                </IconButton>) }

            </Box>
            {/* Breadcrumbs positioned absolutely */}
            <BreadcrumbLinks isMinimized={isMinimized} drawerWidth={drawerWidth} />
            <Paper
                component="main"
                sx={{ mt: 6, ml: 2, flexGrow: 1, paddingTop: 2, paddingLeft: 4, paddingRight: 2,width: { sm: `calc(100% - ${drawerWidth}px)` }}}
                elevation={2}
            >
                <div>
                        <Switch>
                            <Route path="/drawer/" exact component={HomeDashboard} />
                            <Route path="/drawer/contracts" exact component ={Dashboard} />
                            <Route path="/drawer/accounts" exact component={BusinessDataGrid} />
                            <Route path="/drawer/invoices" exact component={InvoiceScreen}/>
                            <Route path="/drawer/search" exact component={BackgroundVideo}/>
                            <Route path="/drawer/search/result" exact render={(props) => <ProfessionalsList short={true} handleSelect={()=>{}}  searchTerm={""}/>} />
                            <Route path="/drawer/profile" exact component={ProfileVendor} />
                            <Route path="/drawer/profile/business" exact component={BusinessPageForm} />
                            <Route path="/drawer/profile/contactInfo" exact component={ProfileContactForm} />
                            <Route path="/drawer/profile/about" exact component={ProfileAboutForm} />
                            <Route path="/drawer/profile/licensing" exact component={ProfileAddLicenseForm} />
                            <Route path="/drawer/profile/serviceDetails" exact component={ProfileServiceDetails} />
                            <Route path="/drawer/profile/projectShowCase" exact component={ProfileProjectShowcase} />
                            <Route path="/drawer/profile/preview" exact component={BusinessProfile} />
                            <Route path="/drawer/projects" exact component={ProjectTable} />
                            <Route path="/drawer/reviews" exact component={VendorReview} />
                            <Route path="/drawer/calendar" exact component={MyCalendar} />
                            <Route path="/drawer/quotes" exact component={EventList} />
                            <Route path="/drawer/quote" exact component={MessagingInterface}/>
                            <Route path="/drawer/vendors" exact component={VendorTable} />
                            <Route path="/drawer/contract/addContract" exact component={AddContract} />
                            {/*<Route path="/vendor-auth" render={() => <Auth type="vendor" />} />
                            <Route path="/servicer-auth" render={() => <Auth type="servicer" />} />
                            <Route path="/dashboard" component={ResponsiveDrawer} /> {/* New route */}
                            <Route path="/drawer/contract/:contractId" component={ContractPage} />
                            <Route path="/drawer/vendor/:vendorId" component={VendorProfile} />
                            <Route path="/drawer/eTask" component={eTask}/>
                            <Route path="/drawer/JobPost" component={JobPostForm}/>
                        </Switch>
                </div>

            </Paper>
        </Box>
        </Box>
        </Box>
    );
}
